@font-face {
  font-family: "SegoeLight";
  src: local("SegoeLight"),
    url(./assets/fonts/segoeuil_light.ttf) format("truetype");
}
@font-face {
  font-family: "SegoeLightItalic";
  src: local("SegoeLightItalic"),
    url(./assets/fonts/seguili_light_italic.ttf) format("truetype");
}
@font-face {
  font-family: "SegoeSemiLight";
  src: local("SegoeSemiLight"),
    url(./assets/fonts/segoeuisl_semilight.ttf) format("truetype");
}
@font-face {
  font-family: "SegoeSemiLightItalic";
  src: local("SegoeSemiLightItalic"),
    url(./assets/fonts/seguisli_semilight_italic.ttf) format("truetype");
}
@font-face {
  font-family: "SegoeRegular";
  src: local("SegoeRegular"),
    url(./assets/fonts/segoeui_regular.ttf) format("truetype");
}
@font-face {
  font-family: "SegoeRegularItalic";
  src: local("SegoeRegularItalic"),
    url(./assets/fonts/segoeuii_regular_italic.ttf) format("truetype");
}
@font-face {
  font-family: "SegoeSemiBold";
  src: local("SegoeSemiBold"),
    url(./assets/fonts/seguisb_semibold.ttf) format("truetype");
}
@font-face {
  font-family: "SegoeSemiBold";
  src: local("SegoeSemiBold"),
    url(./assets/fonts/seguisb_semibold.ttf) format("truetype");
}
@font-face {
  font-family: "SegoeBold";
  src: local("SegoeBold"),
    url(./assets/fonts/segoeuib_Bold.ttf) format("truetype");
}
@font-face {
  font-family: "SegoeBoldItalic";
  src: local("SegoeBoldItalic"),
    url(./assets/fonts/seguisb_semibold.ttf) format("truetype");
}
@font-face {
  font-family: "SegoeBlack";
  src: local("SegoeBlack"),
    url(./assets/fonts/seguibl_black.ttf) format("truetype");
}
@font-face {
  font-family: "SegoeBlackItalic";
  src: local("SegoeBlackItalic"),
    url(./assets/fonts/seguibli_black_italic.ttf) format("truetype");
}
