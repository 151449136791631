* {
  margin: 0;
  padding: 0;
}
html,
body {
  background-color: #eeeeee;
  width: 100%;
  height: 100vh;
  font-family: "SegoeRegular", sans-serif;
  color: #000;
  overflow-x: hidden;
}
.reconnecting-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  pointer-events: none;
  background-color: rgba(3, 1, 1, 0.9);
  color: #fff;
  font-family: "SegoeBold", sans-serif;
  font-size: 25px;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}
.full-height {
  height: 100%;
}
.left-column,
.right-column {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.wrapper-column {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

footer {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0;
  font-family: "SegoeBold", sans-serif;
  font-size: 14px;
  margin-bottom: 1px;
}

footer a, a:link, a:visited, a:hover, a:active {
  text-decoration: none !important;
}
